<template>
  <div class="content">
    <app-cells position="start">
      <router-link
        :to="{ name: 'profile-education' }"
        class="btn btn--link"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Редактирование учебного заведения</h1>
    </app-cells>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group label="Уровень образования" required>
            <v-select
              v-model="form.level"
              :reduce="level => level.id"
              :options="level_options"
              @input="onSetLevel"
              label="id"
              :searchable="false"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.level.$error }"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <template #error>
              <div v-if="$v.form.level.$dirty && !$v.form.level.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.level && form.level !== 9" label="Учебное заведение" required>
            <v-select
              label="name"
              v-model="form.org"
              :filterable="false"
              :options="org_options"
              :clearable="false"
              @search="onOrgSearch"
              @input="onSetOrg"
              placeholder="Введите название учебного заведения"
              class="select"
              :class="{ 'select--error': $v.form.org.$error }"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #no-options="{ search, searching }">
                <div v-if="searching" class="select__noty">
                  По вашему запросу <em>{{ search }}</em> ничего не найдено.
                </div>
                <div v-else class="select__noty">
                  Для поиска организации в справочнике, введите наименование в формате: <span>Название</span> или ИНН организации и выберите значение из выпадающего списка.
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.name }} ({{ option.address }})</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }} ({{ option.address }})</div>
              </template>
            </v-select>
            <template #error>
              <div v-if="$v.form.org.$dirty && !$v.form.org.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.level && form.level === 9" label-for="org" label="Учебное заведение" required>
            <app-input
              v-model="form.org_text"
              id="org"
              placeholder="Введите название учебного заведения"
              :error="$v.form.org_text.$error"
              @change.native="$v.form.org_text.$touch()"
              :key="1"
            />
            <template #error>
              <div v-if="$v.form.org_text.$dirty && !$v.form.org_text.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.level && form.level !== 9" label="Адрес учебного заведения" required>
            <v-select
              v-model="form.org_address"
              :reduce="address => address.address"
              :options="org_address_options"
              :clearable="false"
              :filterable="false"
              @search="onOrgAddressSearch"
              label="address"
              placeholder="Введите адрес учебного заведения"
              class="select"
              :class="{ 'select--error': $v.form.org_address.$error }"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #no-options="{ search, searching }">
                <div v-if="searching" class="select__noty">
                  По вашему запросу <em>{{ search }}</em> ничего не найдено.
                </div>
                <div v-else class="select__noty">
                  Для поиска адреса, введите адрес в формате: <span>Ставрополь, ул. Пушкина, д 65в</span> и выберите значение из выпадающего списка.
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
            </v-select>
            <div
              v-if="form.org_address && form.org_address.length > 50"
              class="select__long"
            >
              {{ form.org_address }}
            </div>
            <template #error>
              <div v-if="$v.form.org_address.$dirty && !$v.form.org_address.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.level && form.level === 9" label-for="org_address" label="Адрес учебного заведения" required>
            <app-input
              v-model="form.org_address"
              @change.native="$v.form.org_address.$touch()"
              id="org_address"
              placeholder="Введите адрес учебного заведения"
              :error="$v.form.org_address.$error"
            />
            <template #error>
              <div v-if="$v.form.org_address.$dirty && !$v.form.org_address.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-show="form.level" label-for="date_start" label="Дата начала обучения" required>
            <app-input
              v-model="form.date_start"
              v-mask="'99.99.9999'"
              @input.native="onCheckStartDate"
              :class="{ 'input--error': $v.form.date_start.$error || $v.age_start.$invalid }"
              id="date_start"
              placeholder="дд.мм.гггг"
            />
            <template #error>
              <div v-if="$v.form.date_start.$dirty && !$v.form.date_start.required">Обязательное поле</div>
              <div v-if="$v.form.date_start.$dirty && !$v.age_start.between">Некорректная дата</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.level" label-for="date_end" label="Дата окончания обучения">
            <app-input
              v-model="form.date_end"
              v-mask="'99.99.9999'"
              @input.native="onCheckEndDate"
              :class="{ 'input--error': $v.form.date_end.$error || $v.age_end.$invalid }"
              id="date_end"
              placeholder="дд.мм.гггг"
            />
            <template #error>
              <div v-if="$v.form.date_end.$dirty && !$v.age_end.between">Некорректная дата</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
<!--          <app-form-group v-if="form.level" label="Подтверждающий документ">-->
<!--            <v-select-->
<!--              v-model="form.document"-->
<!--              :reduce="doc => doc.id"-->
<!--              :options="documents_data"-->
<!--              :searchable="false"-->
<!--              label="id"-->
<!--              placeholder="Выберите значение"-->
<!--              class="select"-->
<!--            >-->
<!--              <template slot="no-options">Добавьте документ об образовании на вкладке документы</template>-->
<!--              <template slot="open-indicator">-->
<!--                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>-->
<!--                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>-->
<!--                </svg>-->
<!--              </template>-->
<!--              <template slot="option" slot-scope="option">-->
<!--                <div class="select__item">-->
<!--                  {{ option.issued_by }} (<span v-if="option.series">серия: {{ option.series }},</span> <span v-if="option.number">номер: {{ option.number }}</span>)-->
<!--                </div>-->
<!--              </template>-->
<!--              <template slot="selected-option" slot-scope="option">-->
<!--                <div class="d-center">-->
<!--                  {{ option.issued_by }} (<span v-if="option.series">серия: {{ option.series }},</span> <span v-if="option.number">номер: {{ option.number }}</span>)-->
<!--                </div>-->
<!--              </template>-->
<!--            </v-select>-->
<!--          </app-form-group>-->
          <app-form-group v-if="form.level && form.level !== 1 && form.level !== 2" label-for="specialty" label="Специальность">
            <app-input
              v-model.trim="form.specialty"
              id="specialty"
              placeholder="Введите свою специальность"
            />
          </app-form-group>
          <app-form-group v-if="form.level && form.level !== 1 && form.level !== 2" label-for="qualification" label="Квалификация">
            <app-input
              v-model.trim="form.qualification"
              id="qualification"
              placeholder="Введите свою квалификацию"
            />
          </app-form-group>
          <app-form-group v-if="form.level && form.level !== 1 && form.level !== 2 && form.level !== 3 && form.level !== 4" label-for="academic_degree" label="Ученая степень">
            <app-input
              v-model.trim="form.academic_degree"
              id="academic_degree"
              placeholder="Введите свою ученую степень"
            />
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button @click="onOrgDelete" theme="error" type="button">Удалить</app-button>
        <app-button ref="submit" :disabled="$v.form.$error">Сохранить</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import {between, required} from 'vuelidate/lib/validators'
import { debounce } from 'lodash'
import { differenceInCalendarYears, parse } from 'date-fns'

export default {
  name: 'ProfileOrganizationEdit',
  data() {
    return {
      form: {},
      level_options: [],
      org_options: [],
      org_address_options: [],
      documents_data: [],
      age_start: 30,
      age_end: 30,
    }
  },
  validations() {
    const form = {
      level: { required },
      org: {},
      org_text: { required },
      org_address: { required },
      date_start: { required },
      date_end: {},
    }

    if (this.form.level !== 9) {
      form.org = { required }
    } else {
      form.org = {}
    }

    return { form, age_start: { between: between(0, 90) }, age_end: { between: between(-10, 90) } }
  },
  beforeMount() {
    this.$store.dispatch('dict/GET_EDU_LEVEL')
      .then(response => { this.level_options = response.data })
    this.$store.dispatch('profile/documents/GET_LIST')
      .then(response => { this.documents_data = response.data.results.filter(item => item.type === 'edu_doc') })
  },
  mounted() {
    this.$store.dispatch('profile/education/GET_ORG_DATA', this.$route.params.id)
      .then(response => { this.form = response.data })
  },
  methods: {
    onSetLevel() {
      for (const key in this.form) {
        if (key !== 'level' && key !== 'id') {
          this.form[key] = ''
        }
      }
    },
    onCheckStartDate() {
      this.$v.form.date_start.$touch()
      const date = parse(this.form.date_start, 'dd.MM.yyyy', new Date())
      this.age_start = differenceInCalendarYears(new Date(), date)
    },
    onCheckEndDate() {
      if (this.form.date_end !== '') {
        this.$v.form.date_end.$touch()
        const date = parse(this.form.date_end, 'dd.MM.yyyy', new Date())
        this.age_end = differenceInCalendarYears(new Date(), date)
      }
    },
    onOrgSearch(search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_EDU_ORG', {search}).then(response => {
        vm.org_options = response.data
        loading(false)
      })
    }, 350),
    onSetOrg() {
      this.form.org_text = this.form.org.name
      this.form.org_address = this.form.org.address
      this.$v.form.org.$touch()
    },
    onOrgAddressSearch (search, loading) {
      loading(true)
      this.searchOrgAddress(loading, search, this)
    },
    searchOrgAddress: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_ADDRESS', {search}).then(response => {
        vm.org_address_options = response.data
        loading(false)
      })
    }, 350),
    onOrgDelete() {
      this.$store.dispatch('profile/education/DELETE_ORG', this.$route.params.id)
        .then(() => {
          this.$router.push({ name: 'profile-education' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Учебное заведение удалено'
          })
        })
        .catch(error => {
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    onCheckForm() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      if (!this.form.date_end) this.form.date_end = null
      // this.$refs.submit.classList.add('preload')
      this.$store.dispatch('profile/education/PATCH_ORG_DATA', { data: this.form, id: this.$route.params.id })
        .then(() => {
          this.$router.push({ name: 'profile-education' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Данные об образовании успешно сохранены.'
          })
        })
        .catch(error => {
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  },
}
</script>
